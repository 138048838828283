import React from 'react';
import {graphql, useStaticQuery, Link} from 'gatsby';
import Img from "gatsby-image";
import Layout from '../../components/layout';
import Intro from '../../components/intro';
import ProjectQuote from '../../components/project-quote';
import ProjectMeta from '../../components/project-meta';
import Logo from '../../assets/logoBHP.svg';
import LogoSQL from '../../assets/microsoft-sql-server.svg';
import LogoWebAPI from '../../assets/microsoft-dot-net.png';
import LogoAngular from '../../assets/angular.svg';
import ProjectWrapper from '../../components/project-wrapper';
import ProjectNav from '../../components/project-nav';
import NavArrow from '../../assets/navArrow.svg';

const BHP = () => {
  const {desktopHome, mobileRegister2, desktopSpanish} = useStaticQuery(graphql`
    query {
      desktopHome: file(relativePath: { eq: "bhp/desktopHome.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mobileRegister2: file(relativePath: { eq: "bhp/mobileRegister2.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopSpanish: file(relativePath: { eq: "bhp/desktopSpanish.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout page="Our Work">
      <Intro>
        <h2>Helping BHP register and track contractors with a multilingual system</h2>
        <p>
          With thousands of external contractors located across Australia and the Americas, BHP needed a 
          solution to make sure everyone who works at BHP is qualified to do so.
        </p>
      </Intro>

      <ProjectWrapper>
        <div className="project">
          <h3>The Project</h3>
          <p>
            This project involved the development of both an internal and external web application with a centralised database. 
            The external application - available in both English and Spanish - allows companies to login and manage their staff profiles.
            The internal application enables BHP to manage contractor profiles and administer the entire solution.
          </p>
          <p>
            Integration was also a vital part of the development to disseminate contractor information and ensure consistency
            across multiple downstream systems within the BHP landscape.
           </p>
        </div>

        <img src={Logo} alt="BHP logo" className="logo" />

        <div className="solution">
          <h3>The Solution</h3>
          <p>We worked with BHP to design and develop a custom solution including:</p>
          <ul>
            <li>External Company Registration</li>
            <li>External Contractor Profile Management</li>
            <li>Internal Contractor Management Workflow</li>
            <li>Platform Administration and Configuration</li>
            <li>Security Analysis and Review</li>
            <li>Language Translation</li>
            <li>Systems integration</li>
          </ul>

          <p>We also assist with the ongoing performance of the platform including:</p>
          <ul>
            <li>System Monitoring</li>
            <li>Support and Maintenance</li>
          </ul>
        </div>

        <div className="screens">
          <Img fluid={mobileRegister2.sharp.fluid} fadeIn alt="Registration page" />
          <Img fluid={desktopSpanish.sharp.fluid} fadeIn alt="Home page in spanish" />
        </div>

        <ProjectMeta>
          <div className="requirements">
            <h3>The Development</h3>
            <p>The key areas of development for this project included:</p>
            <ul>
              <li>Database Design and Development</li>
              <li>Web Application Development</li>
              <li>Translation and Localisation</li>
              <li>Systems Integration</li>
              <li>UX Design</li>
              <li>Responsive Design</li>
            </ul>
          </div>
        </ProjectMeta>

        <Img fluid={desktopHome.sharp.fluid} fadeIn alt="" className="mainImage" />

        <ProjectMeta>
          <h3 className="techHeading">The Technology</h3>
          <div className="techLogos">
            <img src={LogoSQL} alt="Microsoft SQL logo" />
            <img src={LogoWebAPI} alt="Microsoft WebAPI logo" />
            <img src={LogoAngular} alt="Angular logo" />
          </div>
        </ProjectMeta>

        <ProjectQuote>
          The team at Talen work hard to really understand our business processes and often provide options we haven't even thought of.
          This and their attention to detail is what sets them apart from the crowd.
        </ProjectQuote>

        <ProjectNav>
          <div className="prev">
            <img src={NavArrow} />
            <Link to="/work/c-res">C-Res</Link>
          </div>
          <div className="next">
            <Link to="/work/adapt">Adapt</Link>
            <img src={NavArrow} />
          </div>
        </ProjectNav>
      </ProjectWrapper>
    </Layout>
  );
};

export default BHP;
